
import styled from "styled-components";


export const Wrapper = styled.section`
.aboveFooter{
    img{
        width: 100%;
        margin-bottom: -0.2rem;
    }
}
`;