import React from 'react'
import { Wrapper } from './imgBelowNav.style'

const ImgBelowNav = ({bgImg}) => {
  return (
    <Wrapper>
        <div className="belowFooter">
            <img src={bgImg} alt="" />
        </div>
    </Wrapper>
  )
}

export default ImgBelowNav