/** @format */

import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import footerlogo from "../../assets/agri_logo.png";
import callImg from "../../assets/footer-call-icon.png";
import mailImg from "../../assets/footer-mail-icon.png";
import linkedinImg from "../../assets/footer-linkedin-icon.png";
import facebookImg from "../../assets/footer-facebook-icon.png";
import manImg from "../../assets/cart-man.png";
import { Wrapper } from "./footer.style";

const Footer = () => {
  const [show, setShow] = useState(false);

  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === "/allproducts") {
      const show = pathname.includes("/allproducts");
      setShow(show);
    }
    if (pathname.includes("/products/")) {
      const show = pathname.includes("/products/");
      setShow(show);
    }
  }, [pathname]);

  return (
    <Wrapper show={show}>
      <div>
        <div className="footer">
          <div className="overlay"></div>
          <div className="footer-column-1">
            <div className="footer-logo">
              <Link to={"/"}>
                <img src={footerlogo} alt="" />
              </Link>
            </div>
            <h5>
              We are an award-winning creative agency, dedicated to the best
              result in Import & Export, promotion, business consulting, and
              marketing.
            </h5>
          </div>
          <div className="footer-column-2">
            <div className="footer-links-header">
              <h3>Links</h3>
            </div>
            <div className="footer-links">
              <Link to={"/"}>
                <h5>Home</h5>
              </Link>
              <Link to={"/allproducts"}>
                <h5>Our Product</h5>
              </Link>
              {/* <Link to={"/"}>
                <h5>Group of Companies</h5>
              </Link> */}
              <Link to={"/aboutus"}>
                <h5>About Us</h5>
              </Link>
              <Link to={"/certificates"}>
                <h5>Certificates</h5>
              </Link>
              <Link to={"/contact"}>
                <h5>Contact Us</h5>
              </Link>
            </div>
          </div>
          <div className="footer-column-3">
            <div className="footer-address-header">
              <h3>Office Address</h3>
            </div>
            <div className="footer-address-address">
              <h5>
                Rukhamani Krishna, 607, 6th Floor, Nr Arjun Park, Besa-Beltarodi
                Road, Post Box No.440034, Nagpur, Maharashtra, India
              </h5>
            </div>
          </div>
          <div className="footer-column-4">
            <a href="#">
              <img src={callImg} alt="" />
            </a>
            <a href="#">
              <img src={mailImg} alt="" />
            </a>
            <a href="#">
              <img src={linkedinImg} alt="" />
            </a>
            <a href="#">
              <img src={facebookImg} alt="" />
            </a>
          </div>
          <div className="footer-column-5">
            <img src={manImg} alt="" />
          </div>
        </div>
        <div className="footer-copyRight">
          <div className="footer-left">
            <h5>© 2019 P. R.Global Resources All Rights Reserved | </h5>
          </div>
          <div className="footer-right">
            <h5>
              Design & Developed By The <span>Space</span> Element
            </h5>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Footer;
