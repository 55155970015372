import styled from "styled-components";

export const CertificateSecStyle = styled.section`
  margin-top: 5rem;

  .certificate-header {
    padding: 4rem 0rem 1rem 0rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      color: var(--green-color);
      font-size: 3rem;
      font-weight: 400;

      @media screen and (max-width: 768px) {
        font-size: 2rem;
      }
    }

    p {
      font-size: 1.6rem;
      color: var(--gray-color);
      @media screen and (max-width: 768px) {
        font-size: 1rem;
      }
    }
  }
`;

export const CertificateLisContainer = styled.div`
  padding: 2rem 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;

  @media screen and (max-width: 768px) {
    padding: 2rem 1rem;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }
`;
