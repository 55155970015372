import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import { MainStyle } from "./global.style";

import {
  HomePage,
  Products,
  ProductDetail,
  AllProducts,
  Certificates,
  Contact,
  AboutPage,
} from "./pages";
import { Navbar } from "./components";
import { Footer } from "./components";

const App = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <MainStyle>
        <Routes>
          <Route element={<HomePage />} path="/" index />
          <Route element={<Products />} path="/products/:subid" />
          <Route element={<ProductDetail />} path="/productdetail/:id" index />
          <Route element={<AllProducts />} path="/allproducts" />
          <Route element={<Certificates />} path="/certificates" />
          <Route element={<Contact />} path="/contact" />
          <Route element={<AboutPage />} path="/aboutus" />
        </Routes>
      </MainStyle>
      <Footer />
      <Toaster position="top-right" />
    </BrowserRouter>
  );
};

export default App;
