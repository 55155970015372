import styled from "styled-components";

export const NavContainerStyle = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) =>
    props.isScrolled
      ? "1rem 3rem"
      : "1rem 3rem"
      ? props.pathname !== "/"
        ? "1rem 3rem"
        : "1rem 3rem"
      : ""};
  position: fixed;
  top: 0;
  width: 100%;
  background: ${(props) => (props.isScrolled ? "white" : "transparent")};
  box-shadow: ${(props) =>
    props.isScrolled
      ? "0px 4px 19px rgba(0, 0, 0, 0.2)"
      : "none"
      ? props.pathname !== "/"
        ? "0px 4px 19px rgba(0, 0, 0, 0.2)"
        : "none"
      : ""};
  transition: all 0.5s ease-in-out;
  z-index: ${(props) => (props.pathname === "/" ? "5000" : "8000")};

  @media screen and (max-width: 768px) {
    padding: 0.5rem 1rem;
    background: ${(props) =>
      props.pathname !== "/"
        ? "white"
        : props.isScrolled
        ? "white"
        : "transparent"};
  }

  .logo-agri {
    img {
      transition: all 0.5s ease-in-out;
      width: 3rem;
      height: 3rem;
      object-fit: cover;
    }
  }

  ul {
    display: flex;
    gap: 2rem;
    align-items: center;
    position: relative;
    @media screen and (max-width: 768px) {
      display: none;
    }

    li > a {
      transition: 0.2s all ease-in-out;
      text-decoration: none;
      font-size: 1.2rem;
      color: ${(props) =>
        props.isScrolled
          ? "#000000"
          : "white"
          ? props.pathname !== "/"
            ? "#000000"
            : "white"
          : ""};

      &:hover {
        color: var(--green-color);
      }
    }
  }

  .contact-btn {
    button {
      border: 1px solid #dedede;
      padding: 0.6rem 1rem;
      font-family: inherit;
      font-weight: 400;
      font-size: 1.3rem;
      border-radius: 0.5rem;
      background: var(--white-color);
      cursor: pointer;
      color: var(--green-color);
      @media screen and (max-width: 768px) {
        display: none;
      }

      &:focus {
        outline: none;
      }
    }
  }
`;
