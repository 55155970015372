/** @format */

import React, { useState, useEffect } from "react";
import axios from "axios";

import { Link } from "react-router-dom";

import { HoverCardContainer } from "./hovercard.style";

import { IoIosArrowForward } from "react-icons/io";

import { getEndpoints } from "../../utils/Constants";

const HoverCard = ({ setShowPopUp }) => {
  const [categorySubCategory, setCategorySubCategory] = useState([]);

  const fetchCategoryAndSubCategory = async () => {
    try {
      const response = await axios.get(
        getEndpoints.getAllCategoriesSubCategories + "?limit=1000"
      );

      if (response.data.status === "success") {
        setCategorySubCategory(response.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCategoryAndSubCategory();
  }, []);

  return (
    <HoverCardContainer
      onMouseLeave={() => {
        setShowPopUp(false);
      }}
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="categories-wrapper">
        {categorySubCategory?.map((catsub) => (
          <div className="category" key={catsub?.category?._id}>
            <h1>{catsub?.category?.title}</h1>
            <div className="sub-category">
              {catsub?.subcategory?.map((subcategory) => (
                <Link
                  key={subcategory?._id}
                  to={`/products/${subcategory?._id}`}
                >
                  <div className="links-wrapper">
                    <span>
                      <IoIosArrowForward color="#717171" size={8} />
                    </span>

                    <h5 onClick={() => setShowPopUp(false)}>
                      {subcategory?.title}
                    </h5>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>
    </HoverCardContainer>
  );
};

export default HoverCard;
