import styled from "styled-components";

import whoWeAreBG from "../../assets/who-we-are-bg-one.png";
import greenBG from "../../assets/green-bg.png";

export const WrapperStyle = styled.section`
  .sticky-social-media {
    .sticky-email {
      position: fixed;
      /* top: 40%; */
      top: 265px;
      right: 0;
      display: ${(props) => (props.isScrolled ? "flex" : "none")};
      z-index: 999;
      background-color: var(--green-color);

      flex-direction: row;
      align-items: center;

      @media screen and (max-width: 768px) {
        display: none;
      }

      img {
        width: 2rem;
      }
      a {
        h5 {
          display: ${(props) => (props.isHoveredEmail ? "flex" : "none")};
          font-size: 0.7rem;
          margin-left: 0.2rem;
          cursor: pointer;
          color: var(--white-color);
        }
      }
    }
    .sticky-linkedin {
      position: fixed;
      top: 300px;
      right: 0;
      display: ${(props) => (props.isScrolled ? "flex" : "none")};
      z-index: 999;
      background-color: var(--green-color);
      /* padding: 0.1rem 0 0.1rem 0; */
      flex-direction: row;
      align-items: center;

      @media screen and (max-width: 768px) {
        display: none;
      }

      img {
        width: 2rem;
      }
      a {
        h5 {
          display: ${(props) => (props.isHoveredLinkedin ? "flex" : "none")};
          font-size: 0.7rem;
          margin-left: 0.2rem;
          cursor: pointer;
          color: var(--white-color);
        }
      }
    }
    .sticky-facebook {
      position: fixed;
      top: 335px;
      right: 0;
      display: ${(props) => (props.isScrolled ? "flex" : "none")};
      z-index: 999;
      background-color: var(--green-color);

      flex-direction: row;
      align-items: center;

      @media screen and (max-width: 768px) {
        display: none;
      }

      img {
        width: 2rem;
      }
      a {
        h5 {
          display: ${(props) => (props.isHoveredFacebook ? "flex" : "none")};
          font-size: 0.7rem;
          margin-left: 0.2rem;
          cursor: pointer;
          color: var(--white-color);
        }
      }
    }
  }
`;

export const HeroContainerStyle = styled.div`
  height: 100vh;

  .hero-img {
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      z-index: 23;
    }
    img {
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }
  }

  .hero-text {
    max-width: 50rem;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    text-align: center;

    h1 {
      font-size: 3rem;
      color: white;

      @media screen and (max-width: 768px) {
        font-size: 2.2rem;
      }
    }

    p {
      font-size: 1.3rem;
      color: white;
      margin-top: 2rem;
    }

    .btn-container {
      display: flex;
      justify-content: center;
      align-items: center;

      a {
        text-decoration: none;
        border: none;
        font-family: inherit;
        font-weight: bold;
        font-size: 1.3rem;
        color: var(--green-color);
        background-color: var(--white-color);
        padding: 1rem 2rem;
        margin-top: 2rem;
        border-radius: 0.5rem;
        cursor: pointer;
      }
    }
  }
`;

export const ServiceWhoWeAreStyle = styled.div`
  background: url(${whoWeAreBG}) var(--faint-blue) no-repeat bottom right/cover;
  background-size: 60% !important;
  background-position: 100% 100%;
  height: 150vh;

  @media screen and (max-width: 768px) {
    height: 200vh;
    background-size: 100% !important;
  }

  @media screen and (min-width: 280px) {
    height: 210vh;
    background-size: 100%;
  }

  @media screen and (min-width: 375px) {
    height: 230vh;
    background-size: 100%;
  }

  @media screen and (min-width: 390px) {
    height: 180vh;
    background-size: 100%;
  }

  @media screen and (min-width: 414px) {
    height: 160vh;
    background-size: 100%;
  }
`;

export const OurServicesContainerStyle = styled.div`
  padding: 0rem 4rem 0rem 4rem;
  position: relative;
  top: -10rem;

  .swiper,
  swiper-container {
    padding: 3rem 0rem;
  }

  @media screen and (max-width: 768px) {
    padding: 0rem 1rem 0rem 1rem;
  }

  .service-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: var(--white-color);
    border: 1px solid rgba(0, 187, 0, 0.38);
    box-shadow: 0px 0px 38px rgba(0, 187, 0, 0.14);
    border-radius: 0.5rem;
    padding-bottom: 5rem;

    h3 {
      color: var(--green-color);
      margin-top: 2rem;
    }

    p {
      width: 20rem;
      color: var(--gray-color);
      margin-top: 1rem;
      text-align: center;

      @media screen and (max-width: 768px) {
        width: 90%;
      }
    }

    .service-circle {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: white;
      width: 6rem;
      height: 6rem;
      border-radius: 50%;
      border: 1px solid rgba(0, 187, 0, 0.38);
      box-shadow: 0px 0px 21px rgba(41, 171, 226, 0.16);
      padding: 1rem 2rem;
      position: absolute;
      top: 13rem;

      img {
        width: 4rem;
        height: 4rem;
        object-fit: cover;
      }
    }
  }
`;

export const WhoWerContainerStyle = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 0rem 3rem;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    padding: 0rem 1rem;
  }

  .left-panel {
    flex: 1 0 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
      justify-content: unset;
      align-items: unset;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .vegies-img {
      position: absolute;
      width: 30rem;
      height: 30rem;
      z-index: 200;

      @media screen and (max-width: 768px) {
        width: 20rem;
        height: 20rem;
        top: 5rem;
        left: -10rem;
      }
    }

    .grain-img {
      position: absolute;
      top: -20rem;
      left: 17.3rem;
      width: 20rem;
      height: 20rem;
      z-index: 150;

      @media screen and (max-width: 768px) {
        width: 10rem;
        height: 8rem;
        top: 3rem;
        left: -2rem;
      }
    }

    .scientist-img {
      position: absolute;
      top: 3rem;
      left: 3rem;
      width: 15rem;
      height: 15rem;
      z-index: 250;

      @media screen and (max-width: 768px) {
        left: -8rem;
        top: 18rem;
        width: 8rem;
        height: 8rem;
      }
    }
  }

  .right-panel {
    flex: 1 0 50%;
    position: relative;

    @media screen and (max-width: 768px) {
      text-align: center;
    }

    .veg-icon {
      width: 10rem;
      height: 5rem;
      object-fit: cover;
      position: absolute;
      top: -8rem;
      right: 0;

      @media screen and (max-width: 768px) {
        top: -6rem;
        right: -4rem;
      }
    }

    h2 {
      color: var(--green-color);
      font-weight: bold;
      font-size: 3rem;

      @media screen and (max-width: 768px) {
        text-align: center;
        font-size: 2rem;
        padding-top: 2rem;
      }
    }

    p {
      text-align: justify;
      margin-top: 1rem;
      color: var(--light-gray-color);
      line-height: 2rem;

      @media screen and (max-width: 768px) {
        text-align: center;
      }
    }
  }
`;

export const QuoteContainerStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${greenBG}) no-repeat center/cover;
  height: 50vh;
  position: relative;
  padding: 0rem 3rem;

  @media screen and (max-width: 768px) {
    padding: 0rem 1rem;
    height: 30vh;
  }

  .overlay-quote {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.34);
    z-index: 200;
  }

  h3 {
    color: var(--white-color);
    font-size: 2.8rem;
    text-align: center;
    z-index: 300;

    @media screen and (max-width: 768px) {
      font-size: 1rem;
      line-height: 2rem;
    }
  }
`;

export const GetInTouchContaienrStyle = styled.div`
  display: flex;
  padding: 7rem 3rem 0rem 3rem;
  overflow: hidden;
  gap: 1rem;
  height: 100vh;
  background: url(${whoWeAreBG}) no-repeat bottom right/cover;
  background-size: 50%;
  background-position: 100% 100%;
  background-color: var(--faint-blue);

  .get-touch-left {
    flex: 1 0 50%;

    h2 {
      color: var(--green-color);
      font-size: 3rem;
    }

    p {
      margin-top: 1rem;
      line-height: 2rem;
      text-align: justify;
    }

    img {
      margin-top: 2rem;
      width: 18rem;
      height: 18rem;
      object-fit: cover;
    }
  }

  .get-touch-right {
    flex: 1 0 50%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    .pineapple-img {
      position: absolute;
      top: -5rem;
      right: 1rem;
      z-index: 300;
      img {
        width: 10rem;
        height: 100%;
        object-fit: cover;
      }
    }

    .get-touch-card {
      border: 1px solid rgba(0, 187, 0, 0.3);
      box-shadow: 0px 0px 38px rgba(0, 187, 0, 0.1);
      border-radius: 1rem;
      padding: 2rem 1rem;
      background-color: var(--white-color);
      width: 30rem;

      h2 {
        font-weight: lighter;
        font-weight: 400;

        @media screen and (max-width: 768px) {
          font-size: 1.2rem;
        }
      }

      .touch-list {
        margin-top: 1rem;

        .list {
          display: flex;
          align-items: center;
          margin: 1rem 0rem;
          gap: 1rem;

          img {
            width: 2rem;
            height: 2rem;
            object-fit: cover;
          }

          span {
            display: inline-block;
            color: var(--gray-color);
            @media screen and (max-width: 768px) {
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
`;

export const MobileGetInTouchStyle = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 4rem 1rem 0rem 1rem;
  background: url(${whoWeAreBG}) no-repeat bottom center/cover;
  background-size: 100%;
  background-position: 100% 100%;
  background-color: var(--faint-blue);

  .get-touch-header {
    text-align: center;
    margin-top: 1rem;
    h2 {
      color: var(--green-color);
      font-size: 2rem;
    }

    p {
      line-height: 2rem;
      margin-top: 1rem;
    }
  }

  .swiper-touch {
    margin-top: 2rem;
    .get-touch-card {
      border: 1px solid rgba(0, 187, 0, 0.3);
      box-shadow: 0px 0px 38px rgba(0, 187, 0, 0.1);
      border-radius: 1rem;
      padding: 2rem 1rem;
      background-color: var(--white-color);

      h2 {
        font-weight: lighter;
        font-weight: 400;

        @media screen and (max-width: 768px) {
          font-size: 1.2rem;
        }
      }

      .touch-list {
        margin-top: 1rem;

        .list {
          display: flex;
          align-items: center;
          margin: 1rem 0rem;
          gap: 1rem;

          img {
            width: 2rem;
            height: 2rem;
            object-fit: cover;
          }

          span {
            display: inline-block;
            color: var(--gray-color);
            @media screen and (max-width: 768px) {
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }

  .girl-touch {
    margin-top: 2rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

export const WhatWeOfferStyle = styled.div`
  padding: 3rem 0rem;

  .swiper-button-prev {
    background: rgba(255, 255, 255, 0.24);
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white-color);
    backdrop-filter: blur(1rem);
    border: 0.01rem solid var(--white-color);

    &::after {
      font-size: 1.2rem;
      font-weight: bolder;
    }
  }

  .swiper-button-next {
    background: rgba(255, 255, 255, 0.24);
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white-color);
    backdrop-filter: blur(1rem);
    border: 0.01rem solid var(--white-color);

    &::after {
      font-size: 1.2rem;
      font-weight: bolder;
    }
  }

  .offer-header {
    text-align: center;
    h2 {
      color: var(--green-color);
      margin-bottom: 2rem;
      font-size: 3rem;

      @media screen and (max-width: 768px) {
        font-size: 2rem;
      }
    }
  }
  .category-card {
    border-radius: 0.5rem;
    overflow: hidden;
    position: relative;

    .overlay-offer {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.24);
      z-index: 250;
    }

    .text-content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      bottom: 1rem;
      padding: 0rem 2rem;
      z-index: 300;

      span {
        display: inline-block;
        color: var(--white-color);
        font-size: 1.3rem;
        font-weight: 500;
      }

      img {
        width: 5rem;
        height: 3rem;
        object-fit: contain;
      }
    }

    .cat-img {
      img {
        width: 100%;
        height: 20rem;
        object-fit: cover;
      }
    }
  }
`;
