/** @format */

import React, { useState, useEffect } from "react";
import { Wrapper } from "./card.style";
import cardImg from "../../assets/unsplash_pvo1dnoj8tA.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Link } from "react-router-dom";
import { IMG_URL } from "../../utils/Constants";
import { useMainContext } from "../../context/context";

import { Modal } from "../../components";

const Card = ({ product }) => {
  const { setShowModal, showModal } = useMainContext();

  const [swiper, setSwiper] = useState(null);

  // console.log(swiper);

  const cardslideStop = () => {
    swiper.autoplay.stop();
    // console.log("stop")
  };
  const cardslideStart = () => {
    swiper.autoplay.start();
    // console.log("start")
  };

  useEffect(() => {
    window.scrollTo(0, document.body.scrollTop);
    if (swiper) {
      cardslideStop();
    }
  }, []);

  const rupee_format = (str) => {
    if (str) {
      var x = str;
      x = x.toString();
      var lastThree = x.substring(x.length - 3);
      var otherNumbers = x.substring(0, x.length - 3);
      if (otherNumbers !== "") lastThree = "," + lastThree;
      var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
      return res;
    } else {
      return;
    }
  };

  useEffect(() => {
    if (swiper) {
      cardslideStop();
    }
  }, [product?._id]);

  return (
    <>
      <Wrapper>
        <div
          className="card"
          onMouseEnter={cardslideStart}
          onMouseLeave={cardslideStop}
          onClick={cardslideStop}
        >
          <Link to={`/productdetail/${product?._id}`}>
            <div className="card-img">
              <Swiper
                pagination={true}
                modules={[Autoplay, Pagination]}
                slidesPerView={1}
                onSwiper={setSwiper}
                className="mySwiper"
              >
                {product?.images.map((curElem, index) => {
                  return (
                    <SwiperSlide
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={IMG_URL + curElem} alt="" />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </Link>

          <div className="card-name">
            <h3>{product?.product_name || "N/A"}</h3>
          </div>
          <div className="card-category-rating">
            <div className="card-category">
              <h3>{product?.tags[0]}</h3>
            </div>
            {/* <div className="card-rating">
              <h3>{`⭐ ${
                product?.data?.product_avg_rate
                  ? product?.data?.product_avg_rate
                  : "N/A"
              } Ratings`}</h3>
            </div> */}
          </div>
          <div className="card-price-enquiry">
            <div className="card-price">
              <h3>{"₹ " + product?.product_price || "N/A"}</h3>
            </div>
            <div className="card-verticle-border"></div>
            <div className="card-enquiry">
              <button
                onClick={() => {
                  setShowModal(true);
                }}
              >
                Enquiry Now
              </button>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default Card;
