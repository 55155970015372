import axios from "axios";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, {
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { motion } from "framer-motion";
import { Autoplay, Navigation, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { ImgAboveFooter } from "../../components";

import { getEndpoints, IMG_URL } from "../../utils/Constants";

import mailImg from "../../assets/mail-agri-icon.png";
import bgHome from "../../assets/footer-spices-bg.png";
import getInTouchGirl from "../../assets/get-in-touch-girl.png";
import grainsJoinImg from "../../assets/grains-joined.png";
import callImg from "../../assets/call-agri-icon.png";
import pineappleImg from "../../assets/pineapple.png";
import skypeImg from "../../assets/skype-agri-icon.png";
import allVegiesImg from "../../assets/who-we-are-all-veg.png";
import scientistImg from "../../assets/who-we-are-scientiest.png";
import vegIcon from "../../assets/who-we-are-veg.png";
import arrowImg from "../../assets/arrow-img.png";
import gmailIcon from "../../assets/gmailIcon.png-removebg-preview.png";
import facebookIcon from "../../assets/Facebook_Logo_(2019).png";
import linkedinIcon from "../../assets/LinkedIn_icon_circle.svg.png";

import {
  GetInTouchContaienrStyle,
  HeroContainerStyle,
  OurServicesContainerStyle,
  ServiceWhoWeAreStyle,
  QuoteContainerStyle,
  WhoWerContainerStyle,
  MobileGetInTouchStyle,
  WhatWeOfferStyle,
  WrapperStyle,
} from "./homepage.style";

gsap.config({
  nullTargetWarn: false,
});
gsap.registerPlugin(ScrollTrigger);

const HomePage = () => {
  const [heroImages, setHeroImages] = useState([]);
  const [ourServices, setOurServices] = useState([]);
  const [categories, setCategories] = useState([]);

  const [isScrolled, setIsScrolled] = useState(false);
  const [isHoveredFacebook, setIsHoveredFacebook] = useState(false);
  const [isHoveredLinkedin, setIsHoveredLinkedin] = useState(false);
  const [isHoveredEmail, setIsHoveredEmail] = useState(false);

  // eslint-disable-next-line
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );

  const getInTouchContainerRef = useRef(null);
  const cardRefs = useRef([]);
  cardRefs.current = [];

  const createCardRefs = (card) => {
    if (card && !cardRefs.current.includes(card)) {
      cardRefs.current.push(card);
    }
  };

  const fetchHeroImages = async () => {
    try {
      const response = await axios.get(getEndpoints.getAllHomeScreens);

      if (response.data.status === "success") {
        setHeroImages(response.data.data.docs);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchOurServices = async () => {
    try {
      const response = await axios.get(getEndpoints.getAllServices);

      if (response.data.status === "success") {
        setOurServices(response.data.data.docs);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllCategories = async () => {
    try {
      const response = await axios.get(getEndpoints.getAllCategory);

      if (response.data.status === "success") {
        setCategories(response.data.data.docs);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchHeroImages();
    fetchOurServices();
    fetchAllCategories();

    window.scrollTo(0, 0);
  }, []);

  useLayoutEffect(() => {
    if (!matches) {
      let ctx = gsap.context(() => {
        gsap.to(cardRefs.current, {
          yPercent: () => -100 * (cardRefs.current.length - 1),
          ease: "none",
          scrollTrigger: {
            trigger: getInTouchContainerRef.current,
            start: "top top",
            pin: true,
            pinSpacing: true,
            scrub: 1,
            invalidateOnRefresh: true,
            anticipatePin: 1,
            snap: 1 / (cardRefs.current.length - 1),
          },
        });
      }, getInTouchContainerRef);

      return () => ctx.revert();
    }
  }, []);

  const handleNavbar = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  const enterHoverEmail = () => {
    setIsHoveredEmail(true);
  };

  const leaveHoverEmail = () => {
    setIsHoveredEmail(false);
  };

  const enterHoverLinkedin = () => {
    setIsHoveredLinkedin(true);
  };

  const leaveHoverLinkedin = () => {
    setIsHoveredLinkedin(false);
  };

  const enterHoverFacebook = () => {
    setIsHoveredFacebook(true);
  };

  const leaveHoverFacebook = () => {
    setIsHoveredFacebook(false);
  };

  window.addEventListener("scroll", handleNavbar);

  return (
    <section>
      <WrapperStyle
        isScrolled={isScrolled}
        isHoveredEmail={isHoveredEmail}
        isHoveredLinkedin={isHoveredLinkedin}
        isHoveredFacebook={isHoveredFacebook}
      >
        <div className="sticky-social-media">
          <motion.div
            whileHover={{ width: 100 }}
            whileTap={{
              scale: 0.8,
            }}
            className="sticky-email"
            onMouseEnter={enterHoverEmail}
            onMouseLeave={leaveHoverEmail}
          >
            <img src={gmailIcon} alt="Gmail" />
            <a
              href="https://mail.google.com/mail/?view=cm&fs=1&to=export@prpaperindustries.com"
              target="_blank"
              rel="noreferrer"
            >
              <h5>Contact Us</h5>
            </a>
          </motion.div>
          <motion.div
            whileHover={{ width: 100 }}
            whileTap={{
              scale: 0.8,
            }}
            className="sticky-linkedin"
            onMouseEnter={enterHoverLinkedin}
            onMouseLeave={leaveHoverLinkedin}
          >
            <img src={linkedinIcon} alt="LinkedIn" />
            <a
              href="https://www.linkedin.com/company/pr-global-resources-india-pvt-ltd/?originalSubdomain=in"
              target="_blank"
              rel="noreferrer"
            >
              <h5>Contact Us</h5>
            </a>
          </motion.div>
          <motion.div
            whileHover={{ width: 100 }}
            whileTap={{
              scale: 0.8,
            }}
            className="sticky-facebook"
            onMouseEnter={enterHoverFacebook}
            onMouseLeave={leaveHoverFacebook}
          >
            <img src={facebookIcon} alt="facebook" />
            <a
              href="https://www.facebook.com/profile.php?id=100083133087010"
              target="_blank"
              rel="noreferrer"
            >
              <h5>Contact Us</h5>
            </a>
          </motion.div>
        </div>
      </WrapperStyle>
      <HeroContainerStyle>
        <Swiper
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          effect={"fade"}
          loop={true}
          modules={[Autoplay, EffectFade]}
        >
          {heroImages.length > 0 && (
            <Fragment>
              {heroImages.map((heroImg) => (
                <SwiperSlide key={heroImg?._id}>
                  <div className="hero-img">
                    <div className="overlay"></div>
                    <img src={IMG_URL + heroImg?.image} alt={heroImg?.title} />
                  </div>
                  <div className="hero-text">
                    <h1>{heroImg?.title}</h1>

                    {/* <p
                      dangerouslySetInnerHTML={{ __html: heroImg?.description }}
                    ></p> */}

                    <div className="btn-container">
                      <a href="#getintouch">Get in Touch</a>
                      <div className="arrow-line"></div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Fragment>
          )}
        </Swiper>
      </HeroContainerStyle>

      {/* Who We Are and  Services */}
      <ServiceWhoWeAreStyle>
        <OurServicesContainerStyle>
          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            breakpoints={{
              375: {
                slidesPerView: 1,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              999: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              280: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
            }}
          >
            {ourServices.length > 0 && (
              <Fragment>
                {ourServices.map((service) => (
                  <SwiperSlide key={service?._id}>
                    <div className="service-card">
                      <h3>{service?.title}</h3>

                      <p
                        dangerouslySetInnerHTML={{
                          __html: service?.description,
                        }}
                      ></p>

                      <div className="service-circle">
                        <img
                          src={IMG_URL + service?.icon}
                          alt={service?.title}
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Fragment>
            )}
          </Swiper>
        </OurServicesContainerStyle>
        <WhoWerContainerStyle>
          <div className="left-panel">
            <motion.img
              initial={{ rotate: [0, 0] }}
              whileInView={{ rotate: [0, 360] }}
              transition={{ ease: "easeIn", repeat: Infinity, duration: 25 }}
              src={allVegiesImg}
              alt="vegetables"
              className="vegies-img"
            />
            <motion.img
              initial={{ rotate: [0, 0] }}
              whileInView={{ rotate: [0, 10, 0, 10, 0] }}
              transition={{ ease: "easeIn", repeat: Infinity, duration: 10 }}
              src={grainsJoinImg}
              alt="grains"
              className="grain-img"
            />
            <motion.img
              initial={{ translateY: [0, 0] }}
              whileInView={{ translateY: [0, 50, 0] }}
              transition={{ ease: "easeIn", repeat: Infinity, duration: 10 }}
              src={scientistImg}
              alt="people"
              className="scientist-img"
            />
          </div>
          <div className="right-panel">
            <motion.img
              initial={{ x: -100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ ease: "easeIn", duration: 1 }}
              src={vegIcon}
              alt="Vegetables"
              className="veg-icon"
            />
            <motion.h2
              initial={{ y: -100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ ease: "easeIn", duration: 1 }}
              viewport={{ once: true }}
            >
              Who we are
            </motion.h2>
            <motion.p
              initial={{ x: 200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ ease: "easeIn", duration: 1, delay: 0.5 }}
              viewport={{ once: true }}
            >
              P.R. GLOBAL RESOURCES INDIA PRIVATE LIMITED (PRGRIPL) are a Group
              Company of P.R. GLOBAL RESOURCES INDIA (PRGRI) Which is a trusted
              Market Leader and A Well-Known Brand in the International Market
              Since 2016. We are a 100% Export Oriented Units Engaged In to the
              Export, Import, Supporting Manufacturer, Branding And Packaging
              For All Types Of Papers And Paper Boards, Steels ,Metals,
              Aluminum, Chemicals, Flexible Printing Material , Poly & Poly
              Products, Printing & Packaging Material , Agricultural Product,
              Spices, Fruits, Vegetables etc. Company Registered Office Situated
              at Central India ( Zero Miles ) 2.5 K.M From Dr Baba Saheb
              Ambedkar International Air Port, In Nagpur City, Maharashtra
              State, India from where having the Excellent Connectivity Of
              logistics by Road/Rail/Air/*Sea.
            </motion.p>
          </div>
        </WhoWerContainerStyle>
      </ServiceWhoWeAreStyle>

      {/* Quote */}
      <QuoteContainerStyle>
        <div className="overlay-quote"></div>
        <motion.h3
          initial={{ y: -200, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ ease: "easeIn", duration: 1 }}
          viewport={{ once: true }}
        >
          “We are an established and trusted Import Export Agency with a
          reputation for commitment and high integrity”
        </motion.h3>
      </QuoteContainerStyle>
      {/* Get in touch */}
      {!matches ? (
        <GetInTouchContaienrStyle ref={getInTouchContainerRef} id="getintouch">
          <div className="get-touch-left">
            <motion.h2
              initial={{ x: -200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ ease: "easeIn", duration: 1 }}
              viewport={{ once: true }}
            >
              Get in Touch
            </motion.h2>
            <motion.p
              initial={{ x: -200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ ease: "easeIn", duration: 1, delay: 0.5 }}
              viewport={{ once: true }}
            >
              Consumer Awareness is an act of making sure the buyer or consumer
              is aware of the information about products, goods, services, and
              consumers rights. Consumer awareness is important so that buyer
              can take the right decision and make the right choice
            </motion.p>
            <motion.img
              initial={{ x: -200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ ease: "easeIn", duration: 1, delay: 0.8 }}
              viewport={{ once: true }}
              src={getInTouchGirl}
              alt="Girl with vegetables cart"
            />
          </div>
          <div className="get-touch-right">
            <div className="pineapple-img">
              <img src={pineappleImg} alt="Pineapple" />
            </div>
            <div className="get-touch-card" ref={createCardRefs}>
              <h2>Directors</h2>
              <div className="touch-list">
                <div className="list">
                  <img src={callImg} alt="phone icon" />
                  <span>+91-7218719919, +91-9326031739</span>
                </div>
                <div className="list">
                  <img src={mailImg} alt="mail icon" />
                  <span>md@prglobalresources.com</span>
                </div>
                <div className="list">
                  <img src={mailImg} alt="mail icon" />
                  <span>prglobalresources2009@gmail.com</span>
                </div>
                <div className="list">
                  <img src={skypeImg} alt="skype icon" />
                  <span>radharaman.pandey</span>
                </div>
              </div>
            </div>

            <div className="get-touch-card" ref={createCardRefs}>
              <h2>Sales/Export/Marketing</h2>
              <div className="touch-list">
                <div className="list">
                  <img src={callImg} alt="phone icon" />
                  <span>+91-9970428219, +91-9326031739</span>
                </div>
                <div className="list">
                  <img src={mailImg} alt="mail icon" />
                  <span>md@prglobalresources.com</span>
                </div>
                <div className="list">
                  <img src={mailImg} alt="mail icon" />
                  <span>export@prglobalresources.com</span>
                </div>
                <div className="list">
                  <img src={mailImg} alt="mail icon" />
                  <span>marketing@prpaperindustries.com</span>
                </div>
              </div>
            </div>

            <div className="get-touch-card" ref={createCardRefs}>
              <h2>Procurement/Import/Purchase</h2>
              <div className="touch-list">
                <div className="list">
                  <img src={callImg} alt="phone icon" />
                  <span>+91-8275239669, +91-7218719919</span>
                </div>
                <div className="list">
                  <img src={mailImg} alt="mail icon" />
                  <span>procurement@prglobalresources.com</span>
                </div>
                <div className="list">
                  <img src={mailImg} alt="mail icon" />
                  <span>prglobalresources2009@gmail.com</span>
                </div>
                <div className="list">
                  <img src={mailImg} alt="mail icon" />
                  <span>exim@prpaperindustries.com</span>
                </div>
              </div>
            </div>

            <div className="get-touch-card" ref={createCardRefs}>
              <h2>Accounts/Admin</h2>
              <div className="touch-list">
                <div className="list">
                  <img src={callImg} alt="phone icon" />
                  <span>+91-8275239669</span>
                </div>
                <div className="list">
                  <img src={mailImg} alt="mail icon" />
                  <span>info@prglobalresources.com</span>
                </div>
                <div className="list">
                  <img src={mailImg} alt="mail icon" />
                  <span>md@prglobalresources.com</span>
                </div>
                <div className="list">
                  <img src={mailImg} alt="mail icon" />
                  <span>md@prglobalresources.com</span>
                </div>
              </div>
            </div>
          </div>
        </GetInTouchContaienrStyle>
      ) : (
        <MobileGetInTouchStyle id="getintouch">
          <div className="get-touch-header">
            <h2>Get in Touch</h2>
            <p>
              Consumer Awareness is an act of making sure the buyer or consumer
              is aware of the information about products, goods, services, and
              consumers rights. Consumer awareness is important so that buyer
              can take the right decision and make the right choice
            </p>
          </div>
          <div className="swiper-touch">
            <Swiper
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              loop={true}
              modules={[Autoplay]}
            >
              <SwiperSlide>
                <div className="get-touch-card">
                  <h2>Directors</h2>
                  <div className="touch-list">
                    <div className="list">
                      <img src={callImg} alt="phone icon" />
                      <span>+91-7218719919, +91-9326031739</span>
                    </div>
                    <div className="list">
                      <img src={mailImg} alt="mail icon" />
                      <span>md@prglobalresources.com</span>
                    </div>
                    <div className="list">
                      <img src={mailImg} alt="mail icon" />
                      <span>prglobalresources2009@gmail.com</span>
                    </div>
                    <div className="list">
                      <img src={skypeImg} alt="skype icon" />
                      <span>radharaman.pandey</span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="get-touch-card">
                  <h2>Sales/Export/Marketing</h2>
                  <div className="touch-list">
                    <div className="list">
                      <img src={callImg} alt="phone icon" />
                      <span>+91-9970428219, +91-9326031739</span>
                    </div>
                    <div className="list">
                      <img src={mailImg} alt="mail icon" />
                      <span>md@prglobalresources.com</span>
                    </div>
                    <div className="list">
                      <img src={mailImg} alt="mail icon" />
                      <span>export@prglobalresources.com</span>
                    </div>
                    <div className="list">
                      <img src={mailImg} alt="mail icon" />
                      <span>marketing@prpaperindustries.com</span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="get-touch-card">
                  <h2>Procurement/Import/Purchase</h2>
                  <div className="touch-list">
                    <div className="list">
                      <img src={callImg} alt="phone icon" />
                      <span>+91-8275239669, +91-7218719919</span>
                    </div>
                    <div className="list">
                      <img src={mailImg} alt="mail icon" />
                      <span>procurement@prglobalresources.com</span>
                    </div>
                    <div className="list">
                      <img src={mailImg} alt="mail icon" />
                      <span>prglobalresources2009@gmail.com</span>
                    </div>
                    <div className="list">
                      <img src={mailImg} alt="mail icon" />
                      <span>exim@prpaperindustries.com</span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="get-touch-card">
                  <h2>Accounts/Admin</h2>
                  <div className="touch-list">
                    <div className="list">
                      <img src={callImg} alt="phone icon" />
                      <span>+91-8275239669</span>
                    </div>
                    <div className="list">
                      <img src={mailImg} alt="mail icon" />
                      <span>info@prglobalresources.com</span>
                    </div>
                    <div className="list">
                      <img src={mailImg} alt="mail icon" />
                      <span>md@prglobalresources.com</span>
                    </div>
                    <div className="list">
                      <img src={mailImg} alt="mail icon" />
                      <span>md@prglobalresources.com</span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="girl-touch">
            <img src={getInTouchGirl} alt="Girl with vegetables cart" />
          </div>
        </MobileGetInTouchStyle>
      )}
      {/* What we offer you */}
      <WhatWeOfferStyle>
        <div className="offer-header">
          <motion.h2
            initial={{ x: -200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 0.8,
            }}
            viewport={{
              once: true,
            }}
          >
            What We Offer You
          </motion.h2>
        </div>
        <Swiper
          slidesPerView={3}
          spaceBetween={30}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          navigation={true}
          breakpoints={{
            375: {
              slidesPerView: 1,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            999: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            280: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
          }}
          modules={[Navigation, Autoplay]}
        >
          {categories.length > 0 && (
            <Fragment>
              {categories.map((category) => (
                <SwiperSlide key={category?._id}>
                  <div className="category-card">
                    <div className="overlay-offer"></div>
                    <div className="cat-img">
                      <img
                        src={IMG_URL + category.image_url}
                        alt={category.title}
                      />
                    </div>
                    <div className="text-content">
                      <span>{category.title}</span>
                      <img src={arrowImg} alt="arrow" />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Fragment>
          )}
        </Swiper>
      </WhatWeOfferStyle>
      <ImgAboveFooter bgImg={bgHome} />
    </section>
  );
};

export default HomePage;
