import styled from "styled-components";
import { motion } from "framer-motion";

export const HoverCardContainer = styled(motion.div)`
  position: absolute;
  top: 5rem;
  left: 20%;
  background-color: var(--white-color);
  box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.2);
  width: 60%;
  height: 25rem;
  padding: 2rem 2rem;
  border-radius: 0.3rem;
  z-index: 897889;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0.1rem;
  }

  &::-webkit-scrollbar-track {
    background-color: #d1d1d1;
    border-radius: 1rem;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--gray-color);
    border-radius: 1rem;
  }

  .categories-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 2rem;
    row-gap: 2rem;

    .category {
      display: flex;
      flex-direction: column;
      flex: 1 0 20%;

      h1 {
        color: var(--green-color);
        font-size: 0.8rem;
        text-transform: capitalize;
      }

      .sub-category {
        /* margin-top: 0.5rem; */
        .links-wrapper {
          display: flex;
          gap: 0.1rem;
          align-items: center;

          span {
            display: inline-block;
          }
        }
        a {
          text-decoration: none;
          color: black;
          transition: all 0.5s ease;
        }

        h5 {
          margin: 0rem 0rem 0rem 0rem;
          font-weight: 400;
          font-size: 0.7rem;
          text-transform: capitalize;

          &:hover {
            font-weight: bold;
          }
        }
      }
    }
  }
`;
