import React, { useState, useEffect, Fragment } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Divide as Hamburger } from "hamburger-react";
import { AnimatePresence } from "framer-motion";
import queryString from "query-string";

import { Sidebar, HoverCard, GOCCard } from "../../components";

import { NavContainerStyle } from "./navbar.style";

import agriLogo from "../../assets/agri_logo.png";

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [gocPopUp, setGocPopUp] = useState(false);

  const { pathname } = useLocation();

  const navigate = useNavigate();

  let location = queryString.parse(window.location.search);

  const handleNavbar = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleNavbar);

    return () => {
      window.removeEventListener("scroll", handleNavbar);
    };
  }, []);

  return (
    <Fragment>
      <NavContainerStyle pathname={pathname} isScrolled={isScrolled}>
        <div className="logo-agri">
          <Link to="/">
            <img src={agriLogo} alt="logo" />
          </Link>
        </div>
        <ul>
          <li
            onMouseEnter={() => {
              setShowPopUp(false);
              if (gocPopUp) {
                setGocPopUp(false);
              }
            }}
          >
            <Link to="/">Home</Link>
          </li>
          <li
            onMouseEnter={() => {
              setShowPopUp(false);
              if (gocPopUp) {
                setGocPopUp(false);
              }
            }}
          >
            <Link to="/aboutus">About Us</Link>
          </li>
          <li
            onMouseEnter={() => {
              setShowPopUp(true);
              if (gocPopUp) {
                setGocPopUp(false);
              }
            }}
            onClick={() => {
              if (location["catId[]"] || location["subCatId[]"]) {
                location = {};
                navigate("/allproducts");
                window.location.reload();
              }
            }}
          >
            <Link to="/allproducts">Our Products</Link>
          </li>
          <li
            onMouseEnter={() => {
              setShowPopUp(false);
              if (gocPopUp) {
                setGocPopUp(false);
              }
            }}
          >
            <Link to="/certificates">Certificates</Link>
          </li>
          <li
            onMouseEnter={() => {
              setShowPopUp(false);
              setGocPopUp(true);
            }}
          >
            <Link to="/">Group of Companies</Link>
            {gocPopUp && <GOCCard setGocPopUp={setGocPopUp} />}
          </li>
        </ul>

        {showPopUp && <HoverCard setShowPopUp={setShowPopUp} />}

        <div className="contact-btn">
          <Link to={"/contact"}>
            <button type="button">Contact Us</button>
          </Link>
        </div>

        {window.innerWidth < 768 && (
          <div>
            <Hamburger
              color={
                isScrolled
                  ? "#000000"
                  : "#ffffff"
                  ? pathname !== "/"
                    ? "#000000"
                    : "#ffffff"
                  : ""
              }
              rounded
              hideOutline={false}
              toggled={isOpen}
              toggle={setIsOpen}
              label="Show menu"
            />
          </div>
        )}
      </NavContainerStyle>
      <AnimatePresence>
        {isOpen && <Sidebar setIsOpen={setIsOpen} />}
      </AnimatePresence>
    </Fragment>
  );
};

export default Navbar;
