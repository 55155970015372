/** @format */

import React, { useState, useEffect } from "react";
import { ImgAboveFooter, ImgBelowNav } from "../../components";
import bgImg1 from "../../assets/image.png";
import bgImg2 from "../../assets/pngegg - 2023-02-09T162751 1.png";
import { Wrapper } from "./contact.style";
import { toast } from "react-hot-toast";
import axios from "axios";
import { postEndpoints } from "../../utils/Constants";
import smallPlant from "../../assets/plant 1.png";
import fruitSalad from "../../assets/pngegg - 2023-02-09T162902 1.png"

const Contact = () => {
  const [loading, setLoading] = useState(false);

  const contactObj = {
    name: "",
    email: "",
    mobile_no: "",
    subject: "",
    message: ""
  };

  const [formData, setFormData] = useState(contactObj);

  const { name, email, mobile_no, subject, message } = formData;

  const handleOnSubmit = async (event) => {
    setLoading(true);
    clearFormFields()

    const payLoad = {
      name,
      email,
      mobile_no,
      subject,
      message
    };

    try {
      if (validateInputs()) {
        const response = await axios.post(postEndpoints.postContact, payLoad);

        // setData(response.data.data);
        // console.log(response.data)

        if (response.data.status === "success") {
          toast.success(response.data.message);
          setLoading(false);
        } else if (response.data.status === "failed") {
          toast.error(response.data.message);
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };




  const clearFormFields = () => {
    setFormData((prevState) => ({
      ...prevState,
      name: "",
      email: "",
      mobile_no: "",
      location: "",
      subject: "",
      message: "",
    }));
  };




  const validateInputs = () => {
    let validateName = /^[A-Za-z.-]+(\s*[A-Za-z.-]+)*$/;
    let validateEmail = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;
    let validateMobNo =
      /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;

    if (!validateName.test(name)) {
      setLoading(false);
      toast.error("Please enter valid name");
      return false;
    }

    if (!validateEmail.test(email)) {
      setLoading(false);
      toast.error("Please enter valid email");
      return false;
    }

    if (!validateMobNo.test(mobile_no)) {
      setLoading(false);
      toast.error("Please enter valid mobile no");
      return false;
    }

    return true;
  };

  const handleOnChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  useEffect(() => {
    window.scrollTo(0, document.body.scrollTop);
  }, []);

  return (
    <Wrapper>
      <ImgBelowNav bgImg={bgImg1} />

      <div className="getInTouch">
        <div className="fruit-salad">
          <img src={fruitSalad} alt="" />
        </div>
        
        <div className="row-1">
          <img src={smallPlant} alt="" />
        </div>
        <div className="row-2">
          <h3>Get in Touch With Us</h3>
        </div>
        <div className="row-3">
          <h3>Do You’ve Any Question?</h3>
        </div>
        <div className="row-4">
          <h3>Write us a Message</h3>
        </div>
      </div>

      <div className="modal-right">
      <div className="fruit-salad-2">
          <img src={fruitSalad} alt="" />
        </div>
        <div className="modal-info">
          <div className="modal-info-row-1">
            <input
              type="text"
              placeholder="Name"
              onChange={handleOnChange}
              name="name"
              value={name}
              required
            />
            <input
              type="text"
              placeholder="Phone Number"
              onChange={handleOnChange}
              name="mobile_no"
              value={mobile_no}
              required
              maxLength={10}
            />
          </div>
          <div className="modal-info-row-2">
            <input
              type="text"
              placeholder="Email"
              onChange={handleOnChange}
              name="email"
              value={email}
              required
            />
            <input
              type="text"
              placeholder="Subject"
              value={subject}
              onChange={handleOnChange}
              name="subject"
              required
            />
          </div>
          <div className="modal-info-row-3">
            <textarea
              name="message"
              value={message}
              onChange={handleOnChange}
              id=""
              placeholder="Write a Message"
              cols="30"
              rows="10"
            ></textarea>
          </div>
          <div className="modal-button">
            <button onClick={handleOnSubmit}>
              {loading ? "Submitting..." : "Send Message"}
            </button>
          </div>
        </div>
      </div>

      <div className="address">
        <div className="address-title">
          <h3>Address</h3>
        </div>
        <div className="address-container">
          <iframe
            title="prAgri"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14891.692849827627!2d79.0776558!3d21.0757289!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4bf4e99c38913%3A0xd408e7a1fa128427!2sPR%20PAPER%20INDUSTRIES%20INDIA%20PRIVATE%20LIMITED!5e0!3m2!1sen!2sin!4v1677572044209!5m2!1sen!2sin"
            height="400"
            allowFullScreen=""
            style={{
              border: "3px solid #00bb00",
              borderRadius: "7px",
              width: "90%"
            }}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>

      <ImgAboveFooter bgImg={bgImg2} />
    </Wrapper>
  );
};

export default Contact;
