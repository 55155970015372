import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import { ToggleNav } from "../../components";
import { getEndpoints } from "../../utils/Constants";

import { SidebarContainerStyle, SideBarNavStyle } from "./sidebar.style";

const Sidebar = ({ setIsOpen }) => {
  const [catSub, setCatSub] = useState([]);

  const fetchAllCatSubCat = async () => {
    try {
      const response = await axios.get(
        getEndpoints.getAllCategoriesSubCategories + "?limit=1000"
      );

      if (response.data.status === "success") {
        setCatSub(response.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAllCatSubCat();
  }, []);

  return (
    <SidebarContainerStyle
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={() => setIsOpen(false)}
    >
      <SideBarNavStyle
        initial={{ x: "-50%" }}
        animate={{ x: "0%" }}
        exit={{ x: "-50%" }}
        transition={{ type: "tween" }}
        onClick={(e) => e.stopPropagation()}
      >
        <ul>
          <li onClick={() => setIsOpen((prevState) => !prevState)}>
            <Link to="/">Home</Link>
          </li>
          <li onClick={() => setIsOpen((prevState) => !prevState)}>
            <Link to="/aboutus">About Us</Link>
          </li>
        </ul>
        <ToggleNav linkTitle="Our Products" pathName="/allproducts">
          {catSub?.map((catsub) => (
            <ToggleNav
              key={catsub?.category?._id}
              linkTitle={catsub?.category?.title}
            >
              {catsub?.subcategory?.map((subcategory) => (
                <Link
                  key={subcategory?._id}
                  to={`/products/${subcategory?._id}`}
                  className="sub-link"
                >
                  <h5 onClick={() => setIsOpen((prevState) => !prevState)}>
                    {subcategory?.title}
                  </h5>
                </Link>
              ))}
            </ToggleNav>
          ))}
        </ToggleNav>
        <ul>
          <li onClick={() => setIsOpen((prevState) => !prevState)}>
            <Link to="/allproducts">All Products</Link>
          </li>
          <li onClick={() => setIsOpen((prevState) => !prevState)}>
            <Link to="/certificates">Certificates</Link>
          </li>
          <li onClick={() => setIsOpen((prevState) => !prevState)}>
            <Link to="/contact">Contact Us</Link>
          </li>
        </ul>
        <ToggleNav linkTitle="Group of Companies" pathname="/">
          <div className="goc-links">
            <a
              href="https://beta.prglobalresources.com/"
              target="_blank"
              rel="noreferrer"
            >
              PR Paper Industry India
            </a>
            <a
              href="https://prpaperindustries.com/"
              target="_blank"
              rel="noreferrer"
            >
              PR Global Resources India
            </a>
          </div>
        </ToggleNav>
      </SideBarNavStyle>
    </SidebarContainerStyle>
  );
};

export default Sidebar;
