/** @format */

import styled from "styled-components";
import wireImg from "../../assets/who-we-are-bg.png";

export const Wrapper = styled.section`
  .product-detail-page {
    padding: 0rem 3rem;
    margin-top: 5.2rem;
    position: relative;
    overflow: hidden;
    background-color: var(--faint-blue);
    background-image: url(${wireImg});
    /* background-size: cover; */
    /* height: 50vh; */
    background-position: 50% 100%;
    background-repeat: repeat-x;

    .pageNavigation {
      margin: 1rem 0rem;
      h3 {
        font-size: 0.8rem;
        font-weight: 400;
        color: var(--black-color);
      }
    }
    .first-section {
      display: flex;
      gap: 1rem;
      .first-section-left {
        width: 65%;
        img {
          width: 47rem;
          height: 31rem;
          object-fit: cover;
          border-radius: 7px;
        }
      }
      .first-section-right {
        width: 35%;
        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
        border-radius: 7px;
        background-color: var(--white-color);
        display: flex;
        flex-direction: column;
        padding: 1rem 1rem;
        .first-section-right-title {
          h3 {
            font-size: 1.5rem;
            font-weight: 400;
            color: #000000;
            text-transform: capitalize;
          }
        }
        .first-section-price-rating {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #e7e7e7;
          padding: 0.5rem 0 0.5rem 0;
          .first-section-price {
            color: var(--green-color);
            font-size: 1.2rem;
          }
          .first-section-rating {
            h3 {
              font-size: 1rem;
              font-weight: 500;
              color: #727272;
            }
          }
        }
        .first-section-right-images {
          display: flex;
          /* justify-content: ; */
          margin: 1rem 0;
          gap: 2rem;
          img {
            width: 5rem;
            height: 5rem;
            object-fit: cover;
          }
        }
        .first-section-right-detail {
          border-top: 1px solid #e7e7e7;
          border-bottom: 1px solid #e7e7e7;
          padding: 1rem 0;
          .first-section-detail-title {
            h3 {
              color: var(--green-color);
              font-weight: 500;
              font-size: 1.2rem;
            }
          }
          .first-section-detail-p {
            height: 10rem;
            overflow-y: auto;
            &::-webkit-scrollbar {
              width: 3px;
            }

            &::-webkit-scrollbar-thumb {
              background-color: #d9d9d9;
              border-radius: 6px;
            }
            p {
              color: #646464;
              font-size: 1rem;
              font-weight: 400;
            }
          }
        }
        .first-section-right-button {
          width: 100%;
          display: flex;
          justify-content: center;
          margin: 1rem 0 0 0;
          button {
            font-weight: 500;
            font-size: 1rem;
            color: #ffffff;
            background: var(--green-color);
            box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
            border-radius: 7px;
            border: none;
            width: 100%;
            height: 3rem;
            cursor: pointer;
          }
        }
      }
    }
    .second-section {
      margin: 5rem 0 4rem 0;

      .second-section-title {
        h3 {
          font-weight: 500;
          font-size: 1.5rem;
          color: #00bb00;
        }
      }
      .second-section-container {
      }
    }
    .product-detail-bg {
      position: absolute;
      bottom: 0;
      left: 0;
      img {
        width: 100%;
        z-index: -1;
      }
    }
  }

  @media only screen and (max-width: 1240px) {
    .product-detail-page {
      .first-section {
        .first-section-left {
          img {
          }
        }
        .first-section-right {
          .first-section-right-title {
            h3 {
              font-size: 1rem;
              text-transform: capitalize;
            }
          }
          .first-section-price-rating {
            .first-section-price {
              font-size: 0.8rem;
            }
            .first-section-rating {
            }
          }
          .first-section-right-images {
            img {
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .product-detail-page {
      margin-top: 4.5rem;
      padding: 0 1rem 0 1rem;
      .pageNavigation {
        h3 {
          display: none;
        }
      }
      .first-section {
        flex-direction: column;
        .first-section-left {
          width: 100%;
          img {
            width: 100%;
          }
        }
        .first-section-right {
          width: 100%;
          .first-section-right-title {
            order: 2;

            h3 {
              font-size: 1rem;
            }
          }
          .first-section-price-rating {
            order: 3;

            .first-section-price {
              font-size: 0.8rem;
            }
            .first-section-rating {
            }
          }
          .first-section-right-images {
            order: 1;
            gap: 1rem;
            img {
              width: 4rem;
              height: 4rem;
            }
          }
          .first-section-right-detail {
            order: 4;
          }
          .first-section-right-button {
            order: 5;
          }
        }
      }
    }
  }
`;
