/** @format */

import styled from "styled-components";

export const Wrapper = styled.section`
  .card {
    border: 1px solid rgba(0, 187, 0, 0.2);
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    padding: 0rem 0;
    margin: 1rem 0rem 1rem 0rem;
    position: relative;
    background-color: #ffffff;
    .card-img {
      padding: 0 0 0rem 0;
      display: flex;
      .mySwiper {
        img {
          width: 100%;
          height: 200px;
          object-fit: cover;
          border-top-left-radius: 7px;
          border-top-right-radius: 7px;
        }
        .swiper-pagination {
          position: absolute;
          bottom: 0rem;
        }
      }
    }
    .card-name {
      h3 {
        font-weight: 500;
        font-size: 1rem;
        color: #2e2e2e;
        margin: 0.8rem 1rem 0 1rem;
        text-transform: capitalize;
      }
    }
    .card-category-rating {
      margin: 0rem 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e1e0e0;
      padding: 0.2rem 0 0.8rem 0;
      .card-category {
        h3 {
          font-weight: 400;
          font-size: 0.8rem;
          color: #555555;
          text-transform: capitalize;
        }
      }
      .card-rating {
        h3 {
          font-size: 0.8rem;
        }
      }
    }
    .card-price-enquiry {
      margin: 0.5rem 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .card-price {
        /* border-right: 1px solid #E1E0E0; */
        padding: 0.5rem 0.5rem 0.5rem 0;
        h3 {
          color: #00bb00;
          font-weight: 500;
          font-size: 1rem;
        }
      }
      .card-verticle-border {
        height: 2rem;
        width: 0.1rem;
        background-color: #e1e0e0;
      }
      .card-enquiry {
        button {
          background-color: #00bb00;
          color: #ffffff;
          font-size: 0.8rem;
          cursor: pointer;
          outline: none;
          border: none;
          padding: 0.6rem 2rem;
          border-radius: 4px;
        }
      }
    }
  }
`;
