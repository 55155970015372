/** @format */

import styled from "styled-components";

export const Wrapper = styled.section`
  .modal-wrapper {
    background: rgba(0, 0, 0, 0.15);
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 8200;
  }
  .modal {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 58rem;
    height: 37.5rem;
    background-color: #ffffff;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    padding: 1rem 2rem;
    border-radius: 10px;
    z-index: 8200;
    .modal-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin: 1rem 0rem 1rem 0rem;
      .modal-title {
        h3 {
          font-weight: 400;
          font-size: 1.5rem;
          color: var(--blue-color);
        }
      }
      .modal-close {
        img {
          cursor: pointer;
          width: 2.5rem;
        }
      }
    }
    .modal-body {
      display: flex;
      gap: 2rem;
      margin-bottom: 1rem;
      .modal-left {
        background: #ffffff;
        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
        border-radius: 7px;
        display: flex;
        flex-direction: column;
        height: 24rem;
        .modal-img {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          border-bottom: 1px solid #e6e6e6;

          img {
            width: 16rem;
            margin: 1rem 0.5rem 1rem 0.5rem;
            object-fit: cover;
            height: 16rem;
          }
        }
        .modal-name {
          h3 {
            font-weight: 400;
            font-size: 1rem;
            color: #838383;
            margin: 1rem 0 0 1rem;
          }
        }
        .modal-price-rating {
          margin: 1.5rem 1rem 0 1rem;
          width: 90%;
          display: flex;
          justify-content: space-between;
          .modal-price {
            h3 {
              font-weight: 500;
              font-size: 1rem;
            }
          }
          .modal-rating {
            h5 {
              font-weight: 400;
              font-size: 0.7rem;
              color: #6c6c6c;
            }
          }
        }
      }
      .modal-right {
        display: flex;
        flex-direction: column;
        .modal-info {
          .modal-info-row-1 {
            display: flex;
            gap: 2rem;
            margin: 0rem 0rem;
            input {
              background: #ffffff;
              box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
              border-radius: 7px;
              border: none;
              padding: 1rem 1rem;
              width: 100%;
              &:focus {
                outline: none;
              }
            }
          }
          .modal-info-row-2 {
            display: flex;
            gap: 2rem;
            margin: 1rem 0rem;
            input {
              background: #ffffff;
              box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
              border-radius: 7px;
              border: none;
              padding: 1rem 1rem;
              width: 100%;
              &:focus {
                outline: none;
              }
            }
          }
          .modal-info-row-3 {
            display: flex;
            gap: 2rem;
            margin: 1rem 0rem;
            input {
              background: #ffffff;
              box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
              border-radius: 7px;
              border: none;
              padding: 1rem 1rem;
              width: 100%;
              &:focus {
                outline: none;
              }
            }
          }
          .modal-info-row-4 {
            display: flex;
            gap: 2rem;
            margin: 1rem 0rem;
            input {
              background: #ffffff;
              box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
              border-radius: 7px;
              border: none;
              padding: 1rem 1rem;
              width: 100%;
              &:focus {
                outline: none;
              }
            }
          }
        }
        .modal-typeOfUser {
          background: #ffffff;
          box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
          border-radius: 7px;
          .modal-typeOfUser-title {
            h3 {
              font-weight: 400;
              font-size: 1rem;
              color: #2e2e2e;
              padding: 1rem 1.5rem 0 1.5rem;
            }
          }
          .modal-typeOfUser-body {
            display: flex;
            flex-wrap: wrap;
            gap: 2rem;
            padding: 1rem 1.5rem;
            .modal-typeOfUser-child {
              display: flex;
              justify-content: center;
              button {
                width: 9rem;
                background: #ffffff;
                box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
                border-radius: 7px;
                padding: 1rem 0rem;
                color: #6c6c6c;
                font-weight: 500;
                font-size: 0.8rem;
                border: none;
                cursor: pointer;
              }
              button:visited {
                border: 1px solid #29abe2;
              }
            }
          }
        }
        .modal-button {
          margin: 1rem 0 0 0;
          button {
            width: 100%;
            background: #00bb00;
            box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
            border-radius: 7px;
            font-weight: 500;
            font-size: 1.2rem;
            padding: 1rem;
            border: none;
            color: #ffffff;
            cursor: pointer;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 690px) {
    .modal {
      display: flex;
      justify-content: unset;
      align-items: center safe;
      flex-direction: column;
      width: 90%;
      height: 90%;
      background-color: #ffffff;
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      padding: 0rem 1rem 0 1rem;
      border-radius: 10px;
      margin: 1rem 0 1rem 0;
      overflow-y: auto;
      .modal-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 1rem 0rem 1rem 0rem;
        .modal-title {
          h3 {
            font-weight: 400;
            font-size: 1.2rem;
            color: var(--blue-color);
          }
        }
        .modal-close {
          img {
            width: 2rem;
          }
        }
      }
      .modal-body {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        margin-bottom: 2rem;
        .modal-left {
          background: #ffffff;
          box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
          border-radius: 7px;
          display: flex;
          flex-direction: column;
          height: 100%;
          .modal-img {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            border-bottom: 1px solid #e6e6e6;
            img {
              margin: 1rem 1rem;
              width: 100%;
              height: 200px;
              object-fit: cover;
            }
          }
          .modal-name {
            h3 {
              font-weight: 400;
              font-size: 1.2rem;
              color: #838383;
              margin: 1rem 0 0 1rem;
            }
          }
          .modal-price-rating {
            margin: 1rem 1rem 1rem 1rem;
            width: 90%;
            display: flex;
            justify-content: space-between;
            .modal-price {
              h3 {
                font-weight: 500;
                font-size: 1rem;
              }
            }
            .modal-rating {
              h5 {
                font-weight: 400;
                font-size: 0.7rem;
                color: #6c6c6c;
              }
            }
          }
        }
        .modal-right {
          display: flex;
          flex-direction: column;
          .modal-info {
            .modal-info-row-1 {
              display: flex;
              gap: 1rem;
              margin: 0rem 0rem;
              flex-direction: column;
              input {
                background: #ffffff;
                box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
                border-radius: 7px;
                border: none;
                padding: 1rem 1rem;
                width: 100%;
                &:focus {
                  outline: none;
                }
              }
            }
            .modal-info-row-2 {
              display: flex;
              gap: 1rem;
              margin: 1rem 0rem;
              flex-direction: column;
              input {
                background: #ffffff;
                box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
                border-radius: 7px;
                border: none;
                padding: 1rem 1rem;
                width: 100%;
                &:focus {
                  outline: none;
                }
              }
            }
          }
          .modal-typeOfUser {
            background: #ffffff;
            box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
            border-radius: 7px;
            .modal-typeOfUser-title {
              h3 {
                font-weight: 400;
                font-size: 1rem;
                color: #2e2e2e;
                padding: 1rem 1.5rem 0 1.5rem;
              }
            }
            .modal-typeOfUser-body {
              display: flex;
              flex-wrap: wrap;
              gap: 0.9rem;
              padding: 1rem 1.5rem;
              /* justify-content: center; */
              .modal-typeOfUser-child {
                background: #ffffff;
                box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
                border-radius: 7px;
                display: flex;
                justify-content: center;
                width: 5rem;
                button {
                  padding: 1rem 0.2rem;
                  color: #6c6c6c;
                  font-weight: 500;
                  font-size: 0.6rem;
                }
                /* h5 {
                } */
              }
            }
          }
          .modal-button {
            margin: 1rem 0 0 0;
            button {
              width: 100%;
              background: #00bb00;
              box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
              border-radius: 7px;
              font-weight: 500;
              font-size: 1.2rem;
              padding: 1rem;
              border: none;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
`;
