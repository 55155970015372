/** @format */

import styled from "styled-components";

export const Wrapper = styled.section`
  margin: 5rem 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .getInTouch {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    .fruit-salad {
      position: absolute;
      left: 5rem;
      top: 3rem;
      img {
        width: 100%;
        height: 10rem;
        object-fit: cover;
      }
    }

    .row-1 {
      margin: 5rem 0 0 0;
      img {
      }
    }
    .row-2 {
      h3 {
        font-weight: 400;
        font-size: 1.2rem;
      }
    }
    .row-3 {
      margin: 2rem 0 0 0;
      h3 {
        font-weight: 600;
        font-size: 2.5rem;
      }
    }
    .row-4 {
      h3 {
        font-weight: 600;
        font-size: 2.5rem;
      }
    }
  }

  .modal-right {
    margin: 2rem 0 7rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    .fruit-salad-2 {
      position: absolute;
      right: 8rem;
      bottom: -6rem;
      img {
        width: 100%;
        height: 10rem;
        object-fit: cover;
      }
    }
    .modal-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 60%;
      .modal-info-row-1 {
        display: flex;
        gap: 2rem;
        margin: 0rem 0rem;
        width: 100%;

        input {
          background: #ffffff;
          box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
          border-radius: 7px;
          border: none;
          padding: 1rem 1rem;
          width: 100%;
          &:focus {
            outline: none;
          }
        }
      }
      .modal-info-row-2 {
        display: flex;
        gap: 2rem;
        margin: 1rem 0rem;
        width: 100%;
        input {
          background: #ffffff;
          box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
          border-radius: 7px;
          border: none;
          padding: 1rem 1rem;
          width: 100%;
          &:focus {
            outline: none;
          }
        }
      }
      .modal-info-row-3 {
        display: flex;
        gap: 2rem;
        margin: 1rem 0rem;
        width: 100%;

        textarea {
          background: #ffffff;
          box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
          border-radius: 7px;
          border: none;
          padding: 1rem 1rem;
          width: 100%;
          &:focus {
            outline: none;
          }
        }
      }
    }
    .modal-button {
      margin: 1rem 0 0 0;
      display: flex;
      /* flex-direction: column; */
      justify-content: center;
      align-items: center;
      width: 100%;
      button {
        width: 30%;
        background: #00bb00;
        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
        border-radius: 7px;
        font-weight: 500;
        font-size: 1.2rem;
        padding: 1rem;
        border: none;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }

  .address {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f4fff4;
    .address-title {
      margin: 4rem 0 0 0;
      h3 {
        color: #00bb00;
        font-weight: 600;
        font-size: 3rem;
      }
    }
    .address-container {
      display: flex;
      justify-content: center;
      margin: 5rem 0 5rem 0;
      width: 100%;
    }
  }

  @media only screen and (max-width: 690px) {
    margin: 4.1rem 0 0 0;

    .getInTouch {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 0 3rem 0;
      position: relative;
      .fruit-salad {
        position: absolute;
        left: 1rem;
        top: 2rem;
        img {
          width: 100%;
          height: 5rem;
          object-fit: cover;
        }
      }

      .row-1 {
        margin: 5rem 0 0 0;
        img {
        }
      }
      .row-2 {
        h3 {
          font-weight: 400;
          font-size: 1.2rem;
        }
      }
      .row-3 {
        margin: 2rem 0 0 0;
        h3 {
          font-weight: 600;
          font-size: 1.8rem;
        }
      }
      .row-4 {
        h3 {
          font-weight: 600;
          font-size: 1.8rem;
        }
      }
    }

    .modal-right {
      display: flex;
      flex-direction: column;
      position: relative;
      .fruit-salad-2 {
        position: absolute;
        right: 1rem;
        bottom: -6rem;
        img {
          width: 100%;
          height: 5rem;
          object-fit: cover;
        }
      }
      .modal-info {
        width: 90%;
        .modal-info-row-1 {
          display: flex;
          gap: 2rem;
          margin: 0rem 0rem;
          flex-direction: column;
          input {
            background: #ffffff;
            box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
            border-radius: 7px;
            border: none;
            padding: 1rem 1rem;
            width: 100%;
            &:focus {
              outline: none;
            }
          }
        }
        .modal-info-row-2 {
          display: flex;
          gap: 2rem;
          margin: 1rem 0rem;
          flex-direction: column;
          input {
            background: #ffffff;
            box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
            border-radius: 7px;
            border: none;
            padding: 1rem 1rem;
            width: 100%;
            &:focus {
              outline: none;
            }
          }
        }
      }

      .modal-button {
        margin: 1rem 0 0 0;
        button {
          width: 50%;
          background: #00bb00;
          box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
          border-radius: 7px;
          font-weight: 500;
          font-size: 1.2rem;
          padding: 1rem;
          border: none;
          color: #ffffff;
        }
      }
    }
  }
`;
