/** @format */

import styled from "styled-components";
import bgPic from "../../assets/footer-bg.png";

export const Wrapper = styled.section`
  .footer {
    width: 100%;
    background: url(${bgPic}) center/cover no-repeat;
    padding: 3rem 3rem;
    display: flex;
    position: relative;
    gap: 8rem;
    z-index: 10;
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      z-index: -1;
    }
    .footer-column-1 {
      flex-direction: column;
      width: 25%;
      .footer-logo {
        img {
          height: auto;
          width: 9rem;
          margin-top: -1rem;
          object-fit: cover;
        }
      }
      h5 {
        padding: 0rem 0 1rem 0;
        font-size: 1rem;
        color: var(--white-color);
        font-weight: 500;
      }
    }
    .footer-column-2 {
      width: 20%;
      padding-left: 4rem;
      .footer-links-header {
        h3 {
          margin: 1rem 0 1rem 0;
          color: var(--white-color);
          font-weight: 600;
          font-size: 1.5rem;
        }
      }
      .footer-links {
        a {
          text-decoration: none;
          h5 {
            padding: 0.4rem 0;
            color: var(--white-color);
            font-size: 1rem;
          }
        }
      }
    }
    .footer-column-3 {
      width: 22%;
      .footer-address-header {
        h3 {
          margin: 1rem 0 1rem 0;
          color: var(--white-color);
          font-weight: 600;
          font-size: 1.5rem;
        }
      }
      .footer-address-address {
        h5 {
          color: var(--white-color);
          font-size: 1rem;
          font-weight: 400;
        }
      }
    }
    .footer-column-4 {
      position: absolute;
      bottom: 1rem;
      display: flex;
      gap: 1rem;
      left: 3rem;
      img {
        width: 2rem;
      }
    }
    .footer-column-5 {
      img {
        width: 12rem;
        position: absolute;
        bottom: -9px;
        right: 1rem;
      }
    }
  }
  .footer-copyRight {
    display: flex;
    justify-content: center;
    padding: 0 3rem;
    align-items: center;
    height: 2rem;
    background-color: #d1ffd1;
    .footer-left {
      h5 {
        font-weight: 500;
        font-size: 0.6rem;
        color: #2e2e2e;
      }
    }
    .footer-right {
      h5 {
        margin-left: 0.2rem;
        font-weight: 500;
        font-size: 0.6rem;
        color: #2e2e2e;
        span {
          color: rgb(236, 32, 36);
        }
      }
    }
  }

  /* Responsive */

  @media only screen and (max-width: 1315px) {
    .footer {
      gap: 4rem;
      .footer-column-1 {
        width: 30%;
      }
      .footer-column-2 {
        width: 25%;
      }
      .footer-column-3 {
        width: 28%;
      }
    }
  }

  @media only screen and (max-width: 867px) {
    .footer {
      gap: 4rem;
      .footer-column-1 {
        width: 35%;
      }
      .footer-column-2 {
        width: 35%;
      }
      .footer-column-3 {
        width: 30%;
      }
      .footer-column-5 {
        display: none;
      }
    }
  }

  @media only screen and (max-width: 650px) {
    .footer {
      flex-direction: column;
      gap: 1rem;
      padding: 1rem 1.5rem 3.5rem 1.5rem;
      .footer-column-1 {
        width: 100%;
        display: flex;
        /* justify-content: center; */
        align-items: center;
        h5 {
          text-align: center;
        }
      }
      .footer-column-2 {
        width: 100%;
      padding-left: 0.5rem;
        .footer-links {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          height: 150px;
          h5 {
          }
        }
      }
      .footer-column-3 {
        width: 100%;
      }
      .footer-column-4 {
        bottom: 0.5rem;
        width: 75%;
        display: flex;
        justify-content: center;
        a {
          img {
            width: 1.5rem;
          }
        }
      }
    }
    .footer-copyRight {
      padding: 0 0.5rem;
      margin-bottom: ${(props) => (props.show ? "1rem" : "0rem")};

      .footer-left {
        h5 {
          font-size: 0.5rem;
        }
      }
      .footer-right {
        h5 {
          font-size: 0.4rem;
        }
      }
    }
  }
`;
