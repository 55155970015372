/** @format */

import axios from "axios";
import { createContext, useContext, useState, useEffect } from "react";
import { postEndpoints } from "../utils/Constants";
import { toast } from "react-hot-toast";

const storeContext = createContext();

const AppProvider = ({ children }) => {
  const [showModal, setShowModal] = useState(false);
  const [productsList, setProductsList] = useState([]);
  const [id, setId] = useState();
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [otpLoading, setOtpLoading] = useState();
  const [data, setData] = useState();

  const [seconds, setSeconds] = useState(30);
  const [resend, setResend] = useState(false);

  // To check DATA response of enquiry api
  // console.log(data)

  useEffect(() => {
    if (seconds === 0) {
      setResend(true);
    }
  }, [seconds]);

  const timerFunc = async () => {
    const payload = {
      _id: data,
    };

    try {
      const response = await axios.post(postEndpoints.postResend, payload);
      console.log("Resending otp");
      console.log(response);

      if (response.data.status === "success") {
        toast.success(response.data.message);
      }
      if (response.data.status === "failed") {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const resendFucn = () => {
    setSeconds(30);
    startTimer();
    setResend(false);
    timerFunc();
  };

  const startTimer = () => {
    const response = setInterval(() => {
      setSeconds((seconds) => {
        if (seconds === 0) {
          clearInterval(response);
          return 0;
        } else {
          return seconds - 1;
        }
      });
    }, 1000);
  };

  return (
    <storeContext.Provider
      value={{
        showModal,
        setShowModal,
        productsList,
        setProductsList,
        setId,
        id,
        showOtpModal,
        setShowOtpModal,
        setOtpLoading,
        otpLoading,
        data,
        setData,
        seconds,
        setSeconds,
        startTimer,
        resend,
        resendFucn,
      }}
    >
      {children}
    </storeContext.Provider>
  );
};

const useMainContext = () => {
  return useContext(storeContext);
};

export { storeContext, AppProvider, useMainContext };
