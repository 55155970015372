import styled from "styled-components";

export const MobileFilterContainer = styled.div`
  background: var(--green-color);
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  z-index: 7999;
  padding: 0.8rem 3rem;

  .sort-filter-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .sort {
      display: flex;
      gap: 0.5rem;
      align-items: center;

      strong {
        display: inline-block;
        color: var(--white-color);
      }
    }

    .filter {
      display: flex;
      gap: 0.5rem;
      align-items: center;

      strong {
        display: inline-block;
        color: var(--white-color);
      }
    }

    .div-line {
      width: 0.1rem;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(50%, -50%);
      background: var(--white-color);
    }
  }
`;

export const MobFilterStyle = styled.div`
  position: absolute;
  left: 0;
  bottom: 2.8rem;
  width: 100%;
  height: 30rem;
  background-color: var(--white-color);
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;

  .category-filter-mob {
    h1 {
      padding: 1rem 0rem;
      text-align: center;
      font-weight: 400;
      text-transform: uppercase;
      color: var(--green-color);
    }

    .divider {
      width: 100%;
      height: 0.1rem;
      background-color: #d1d1d1;
    }

    .categories-container {
      padding: 1rem 1.5rem;

      h2 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1rem;
        color: var(--green-color);
      }

      .category-wrapper {
        margin-top: 1rem;

        .category-content {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          margin: 0rem 0rem;

          h3 {
            font-weight: 400;
            font-size: 0.8rem;
            color: var(--gray-color);
            width: 15rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }

    .sub-categories-container {
      padding: 1rem 1.5rem;

      h2 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1rem;
        color: var(--green-color);
      }

      .sub-category-wrapper {
        margin-top: 1rem;

        .sub-cat-content {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          margin: 0rem 0rem;

          h3 {
            font-weight: 400;
            font-size: 0.8rem;
            color: var(--gray-color);
            width: 15rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }

    .price-container {
      padding: 1rem 1.5rem 2rem 1.5rem;

      h2 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1rem;
      }

      .price-content-wrapper {
        margin-top: 1rem;
      }
    }
  }
`;
