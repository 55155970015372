import styled from "styled-components";

export const ProductsSecStyle = styled.section`
  padding: 2rem 3rem;
  margin-top: 4rem;

  @media screen and (max-width: 768px) {
    padding: 2rem 1rem;
  }

  .navigation-products {
    h1 {
      color: #2e2e2e;
      font-size: 1rem;
      text-decoration: none;
      font-weight: 500;

      span {
        display: inline-block;
        cursor: pointer;

        a {
          text-decoration: none;
          color: #2e2e2e;
        }
      }
    }
  }
`;

export const PRFilterContainerStyle = styled.div`
  display: flex;
  margin-top: 2rem;
  gap: 1rem;

  .category-filter {
    flex: 1 0 20%;
    background: var(--white-color);
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    height: fit-content;

    @media screen and (max-width: 768px) {
      display: none;
    }

    h1 {
      padding: 1rem 0rem;
      text-align: center;
      font-weight: 400;
      color: var(--green-color);
      text-transform: uppercase;
      font-size: 1.2rem;
    }

    .filter-divider {
      width: 100%;
      height: 0.1rem;
      background-color: #d1d1d1;
    }

    .categories-container {
      padding: 1rem 1.5rem;
      overflow-y: scroll;
      height: 20rem;

      &::-webkit-scrollbar {
        width: 0.1rem;
      }

      &::-webkit-scrollbar-track {
        background-color: #d1d1d1;
        border-radius: 1rem;
      }

      &::-webkit-scrollbar-thumb {
        background: var(--gray-color);
        border-radius: 1rem;
      }

      h2 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1rem;
        color: var(--green-color);
      }

      .category-wrapper {
        margin-top: 1rem;

        .category-content {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          margin: 1rem 0rem;

          h3 {
            font-weight: 400;
            color: var(--gray-color);
            font-size: 0.7rem;
            width: 10rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-transform: capitalize;
          }
        }
      }
    }

    .sub-categories-container {
      padding: 1rem 1.5rem;
      height: 20rem;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 0.1rem;
      }

      &::-webkit-scrollbar-track {
        background-color: #d1d1d1;
        border-radius: 1rem;
      }

      &::-webkit-scrollbar-thumb {
        background: var(--gray-color);
        border-radius: 1rem;
      }

      h2 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1rem;
        color: var(--green-color);
      }

      .sub-category-wrapper {
        margin-top: 1rem;

        .sub-cat-content {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          margin: 1rem 0rem;

          h3 {
            font-weight: 400;
            font-size: 0.7rem;
            color: var(--gray-color);
            width: 10rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-transform: capitalize;
          }
        }
      }
    }

    .price-container {
      padding: 1rem 1.5rem 2rem 1.5rem;

      h2 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1.2rem;
        color: var(--green-color);
      }

      .price-content-wrapper {
        margin-top: 1rem;
      }
    }
  }

  .products-list-container {
    flex: 1 0 80%;

    .search-sort-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;

      .search-input {
        position: relative;
        flex: 1 0 70%;

        @media screen and (max-width: 768px) {
          flex: 1 0 100%;
        }

        input {
          width: 100%;
          border: none;
          border-radius: 0.5rem;
          padding: 1rem 3rem;
          font-family: inherit;
          font-size: 0.9rem;
          box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);

          @media screen and (max-width: 768px) {
            margin-left: unset;
            width: 100%;
          }

          &:focus {
            outline: none;
          }
        }

        .search-icon {
          position: absolute;
          top: 0;
          left: 2rem;
          transform: translate(-50%, 50%);
          color: var(--gray-color);
        }
      }

      .sort-filter {
        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
        padding: 1rem 1rem;
        cursor: pointer;

        @media screen and (max-width: 768px) {
          display: none;
        }

        .sort-by {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 1rem;

          .sort-text {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 1rem;

            span {
              display: inline-block;
              color: var(--gray-color);
            }
          }

          .chevron-down {
            color: var(--gray-color);
          }
        }
      }
    }

    .products-array {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
      grid-row-gap: 1rem;
      grid-column-gap: 1rem;

      /* @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 0rem;
        column-gap: 0rem;
      } */
    }

    .load-more-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;

      button {
        padding: 1rem 3rem;
        background-color: var(--green-color);
        border: none;
        font-family: inherit;
        font-weight: bold;
        font-size: 1.2rem;
        cursor: pointer;
        border-radius: 0.5rem;
        color: white;

        &:focus {
          outline: none;
        }
      }
    }
  }
`;
