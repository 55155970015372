import styled from "styled-components";

export const NavLinkStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0rem 0rem 0rem;

  strong {
    display: inline-block;
    font-weight: 400;
    text-transform: capitalize;
    a {
      text-decoration: none;
      color: black;
      &.active {
        color: ${(props) =>
          props.activeNav
            ? "var(--green-color)"
            : !props.activeNav
            ? "black"
            : ""};
      }
    }
  }
`;
