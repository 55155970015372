/** @format */

import React, { useEffect, useState, useLayoutEffect } from "react";
import { Wrapper } from "./productdetail.style";
import { Card, Modal } from "../../components";
import { getEndpoints } from "../../utils/Constants";
import axios from "axios";
import { useParams } from "react-router-dom";
import { IMG_URL } from "../../utils/Constants";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useMainContext } from "../../context/context";

const ProductDetail = () => {
  const [singleProduct, setSingleProduct] = useState({});
  const [similarProducts, setSimilarProducts] = useState([]);
  const [sendCatId, setSendCatId] = useState("");
  const [showImage, setShowImage] = useState();
  const [productData, setProductData] = useState({});
  const [desc, setDesc] = useState();
  const { showModal, setShowModal, setId } = useMainContext();
  const { id } = useParams();

  // console.log(id)

  // console.log(singleProduct.data);

  // console.log(similarProducts?.data?.data.docs)
  // console.log(sendCatId)

  useLayoutEffect(() => {
    setShowImage(IMG_URL + singleProduct?.data?.images[0]);
    window.scrollTo(0, document.body.scrollTop);
  }, [singleProduct]);

  const fetchSingleProduct = async (url) => {
    const res = await axios.get(getEndpoints.getAllSingleProduct + url);
    setSingleProduct(res.data);
    setSendCatId(res.data.data.agri_category_id);
    setDesc(res.data.data.detail_description);
  };

  useEffect(() => {
    fetchSingleProduct(id);
  }, [id]);

  const fetchSimilarProducts = async (CatId) => {
    const res = await axios.post(
      getEndpoints.getAllSimilarProduct + `?catId=${CatId}`
    );
    setSimilarProducts(res);
  };

  useEffect(() => {
    fetchSimilarProducts(sendCatId);
  }, [sendCatId]);

  // useEffect(() => {
  //   window.scrollTo(0, document.body.scrollTop);
  // }, []);

  useEffect(() => {
    setId(id);
  }, []);

  return (
    <Wrapper>
      <div className="product-detail-page">
        <div className="pageNavigation">
          <h3>{singleProduct?.data?.product_name}</h3>
        </div>
        <div className="first-section">
          <div className="first-section-left">
            <img src={showImage} alt="" />
          </div>
          <div className="first-section-right">
            <div className="first-section-right-title">
              <h3>
                {singleProduct?.data?.product_name
                  ? singleProduct?.data?.product_name
                  : "N/A"}
              </h3>
            </div>
            <div className="first-section-price-rating">
              <div className="first-section-price">
                <h3>{`₹ ${
                  singleProduct?.data?.product_price
                    ? singleProduct?.data?.product_price
                    : "N/A"
                }`}</h3>
              </div>
              {/* <div className="first-section-rating">
                <h3>{`⭐ ${
                  singleProduct?.data?.product_avg_rate
                    ? singleProduct?.data?.product_avg_rate
                    : "N/A"
                } Ratings`}</h3>
              </div> */}
            </div>
            <div className="first-section-right-images">
              {singleProduct?.data?.images.map((curElem, index) => {
                // console.log(IMG_URL + curElem)
                return (
                  <img
                    key={index}
                    src={IMG_URL + curElem}
                    alt=""
                    onClick={() => setShowImage(IMG_URL + curElem)}
                  />
                );
              })}
            </div>
            <div className="first-section-right-detail">
              <div className="first-section-detail-title">
                <h3>Detail</h3>
              </div>
              <div className="first-section-detail-p">
                <div dangerouslySetInnerHTML={{ __html: desc }} />
              </div>
            </div>
            <div className="first-section-right-button">
              <button
                onClick={() => {
                  setShowModal(true);
                  setProductData(singleProduct?.data);
                }}
              >
                Get Latest Price
              </button>
            </div>
          </div>
        </div>
        {/* {showModal && <Modal product={singleProduct?.data} />} */}
        {showModal && <Modal product={productData} />}

        <div className="second-section">
          <div className="second-section-title">
            <h3>Similar Products</h3>
          </div>
          <div className="second-section-container">
            {similarProducts && (
              <Swiper
                navigation={false}
                spaceBetween={30}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
                modules={[Autoplay]}
                breakpoints={{
                  1300: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                  725: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                  },
                }}
                className="mySwiper"
              >
                {similarProducts?.data?.data.docs.map((curElem, index) => {
                  // console.log(curElem)
                  return (
                    <SwiperSlide key={index}>
                      <div onClick={() => setProductData(curElem)}>
                        {<Card product={curElem} />}
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default ProductDetail;
